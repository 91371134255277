
function setCookie(name, value)
{
  document.cookie=name + "=" + escape(value) + "; path=/;"
}    

function read_cookie(key)
{
    var result;
    return (result = new RegExp('(?:^|; )' + encodeURIComponent(key) + '=([^;]*)').exec(document.cookie)) ? (result[1]) : null;
}

var modal_open = true;


$(document).on('turbolinks:load', function () {
    console.log("here")
    $(".checkmark").on('click', function () {
        $('input[name="specialty"]').attr("checked", false);

        $(this).prop("checked", true).prev().attr("checked", true);
    })
    $( "#specialty-form" ).submit(function( event ) {
        setCookie("specialty", $(this).find('input[name="specialty"]:checked').val())
        console.log(read_cookie("specialty"))
        console.log($(this).find('input[name="specialty"]:checked').val())
        $('.popup-wrapper').hide();
        location.reload();

        event.preventDefault();
    });
    $('.close-modal').on('click', function () {
        $('.popup-wrapper').hide()

    })
    $('#dismiss-specialty').on('click', function () {
        $('.popup-wrapper').hide()

    })

    $("body").on('keydown', function(e) {
        if(modal_open) {
            console.log(e.target)
    
            if (e.which === 9) {
              const focusable =   $("#specialty-popup").find("button, input");
              if (focusable.length) {
                const first = focusable.first().get(0);
                const last = focusable.last().get(0);
                const shift = e.shiftKey;
                if (shift) {
                    if (e.target === first) { // shift-tab pressed on first input in dialog
                      $(last).focus();
                      e.preventDefault();
                    }
                  } else {
                      if (e.target === last) { // tab pressed on last input in dialog
                          $(first).focus();
                          e.preventDefault();
                      }
                  }
              }
          
          }
    
      }
      });


});
