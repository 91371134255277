function read_cookie(key)
{
    var result;
    return (result = new RegExp('(?:^|; )' + encodeURIComponent(key) + '=([^;]*)').exec(document.cookie)) ? (result[1]) : null;
}
$(document).on('turbolinks:load', function() {

    $(".cookie-banner button").on('click', function () {
        $(this).closest(".banner").hide();

        document.cookie = "cookieconfirm=true; expires=Thu, 18 Dec 2025 12:00:00 UTC; path=/";

      })
      if (read_cookie("cookieconfirm")) {
        $(".cookie-banner").hide();
      }
      else {
        $(".cookie-banner").show();

      }
    $(".healthcare-banner button").on('click', function () {
        $(this).closest(".banner").hide();

        document.cookie = "hccookieconfirm=true; expires=Thu, 18 Dec 2025 12:00:00 UTC; path=/";

      })
      if (read_cookie("hccookieconfirm")) {
        $(".healthcare-banner").hide();
      }
      else {
        $(".healthcare-banner").show();

      }
    $('.search-icon').on('click', function() {
        $('.mobile-search-wrapper').toggle('show');
    
    });
    var burgeropen = false;
  $("body").on('keydown', function(e) {
    if(burgeropen) {
        console.log(e.target)

        if (e.which === 9) {
          const focusable =   $(".mobile-nav-links").find("a");
          if (focusable.length) {
            const first = focusable.first().get(0);
            const last = focusable.last().get(0);
            const shift = e.shiftKey;
            if (shift) {
                if (e.target === first) { // shift-tab pressed on first input in dialog
                  $(last).focus();
                  e.preventDefault();
                }
              } else {
                  if (e.target === last) { // tab pressed on last input in dialog
                      $(first).focus();
                      e.preventDefault();
                  }
              }
          }
       
      
      }
      if (e.which === 27 ) {
        e.preventDefault();
        $('.mobile-nav-links').toggle('show');
        burgeropen = false;
        $(".burger-icon.menu-toggle").attr("aria-expanded", "false");

      }

  }
  });
    
    $('.burger-icon').on('click', function() {
      if (burgeropen) {
        $('.mobile-nav-links').toggle('show');
        burgeropen = false;
        $(".burger-icon.menu-toggle").attr("aria-expanded", "false");

      } else {
        burgeropen = true;
        $(".burger-icon.menu-toggle").attr("aria-expanded", "true");
        $('.mobile-nav-links').toggle('show');
        $('.mobile-nav-links').find("li a").first().focus();
      }
   
    });

    
})