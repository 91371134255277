$(document).on('turbolinks:load', function () {
    $(".filter-tab.search a").on('click', function() {
   clickTab($(".filter-tab.search a").index(this),  $(this).data("tab-name"));
   
   
    });

    $(".filter-tab.search a").on("keyup", function(e) {
        tabKeydown($(".filter-tab.search a").index(this), e);

    });
    $(".filter-tab.search a").on('focus', function(e) {
        clickTab($(".filter-tab.search a").index(this), $(this).data("tab-name") );
     

    });
    var tabKeydown = function(index, e) {
            var $thisTab =  $(".filter-tab.search a").eq(index);
            var keyCode = e.which,
                $nextTab = $thisTab.parent().next().is('li') ? $thisTab.parent().next().find('a') : false,
                $previousTab = $thisTab.parent().prev().is('li') ? $thisTab.parent().prev().find('a') : false,
                $firstTab = $thisTab.parent().parent().find('li:first').find('a'),
                $lastTab = $thisTab.parent().parent().find('li:last').find('a');


                switch(keyCode) {
                    // Left/Up
                    case 37:
                    case 38:
                        e.preventDefault();
                        e.stopPropagation();
        
                        if (!$previousTab) {
                            $lastTab.focus();
                        } else {
                            $previousTab.focus();
                        }
        
                        break;
        
                    // Right/Down
                    case 39:
                    case 40:
                        e.preventDefault();
                        e.stopPropagation();
        
                        if (!$nextTab) {
                            $firstTab.focus();
                        } else {
                            $nextTab.focus();
                        }
        
                        break;
        
                    // Home
                    case 36:
                        e.preventDefault();
                        e.stopPropagation();
        
                        $firstTab.focus();
        
                        break;
        
                    // End
                    case 35:
                        e.preventDefault();
                        e.stopPropagation();
        
                        $lastTab.focus();
        
                        break;
        
                    // Enter/Space
                    case 13:
                    case 32:
                        e.preventDefault();
                        e.stopPropagation();
        
                        break;
                }

    }

    var clickTab = function(index, cosmetic) {
        $(".filter-tab.search a").attr({
            'tabindex': -1,
            'aria-selected': 'false'
        })
        .removeAttr('aria-describedby')
        .removeClass('selected');
        $(".filter-tab a").eq(index).attr({
        'tabindex': 0,
        'aria-selected': 'true',
        'aria-describedby': 'tab-widget-description'
         }).addClass('selected');
        if(index == 0) {
            $(".item-link-whole").attr('aria-hidden', 'false').show();

        } else {
            $(".item-link-whole").attr('aria-hidden', 'true').hide();
            console.log(cosmetic);
            $(".item-link-whole[data-cosmetic='" + cosmetic + "'").attr('aria-hidden', 'false').show();
            $(".tab-crumb").remove();
        
        }
        $('.breadcrumb ol').append('<li class="tab-crumb">'+ $(".filter-tab.search a").eq(index).html()+'</li>')

        
  
 
   
    }



});
