$( document ).ready(function() {
  $(".crumb-wrapper").hover(
    function () {
      $(".crumb-wrapper .crumb-title").removeClass("current-crumb");
      child = $(this).find(".crumb-title").eq(0)
      if ($(this).find(".active").length !== 0) {
        $(this).find(".active").addClass("current-crumb");

      } else {
        $(this).find(".crumb-title").eq(0).addClass("current-crumb");

      }
      
    },
    function() {
  
    }
  )

  $(".breadcrumb-container").hover(function(){
    $(".crumb-wrapper .crumb-title").removeClass("current-crumb");

  }, function() {
    $(".crumb-wrapper .crumb-title").removeClass("current-crumb");

    $(".crumb-wrapper .active").addClass("current-crumb");

  });

});
